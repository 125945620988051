import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const DownloadExcel = (data, name_file, isMultiple = false) => {
  // create workbook and worksheet
  const workbook = XLSX.utils.book_new();

  if (isMultiple) {
    // create worksheet from data
    for (const item of data) {
      const worksheet = XLSX.utils.json_to_sheet(item.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, item.name);
    }
  } else {
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
  }


  // generate Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // download Excel file
  const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, name_file + ".xlsx");
}
