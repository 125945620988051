import { addCommas2 } from "src/lib/helpers";
import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import API from "src/api";
import NotifToast from "src/components/notificationModal/NotifToast";

export default class ModalCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      required: ['qty'],
      body: {
        manufacturer_id: null,
        qty: null,
        start_serial: '',
      },
      notif: {
        show: false,
        title: '',
        message: '',
        status: true,
      },
      downloadPath: null,
      qty: 0,
    }
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is required'
    }
    else {
      delete error[name]
    }

    if (name === 'qty') {
      newBody[name] = parseInt(value.replace(/\D/g, '')) || 0;
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && this.state.required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({
      // notif: {
      //   ...this.state.notif,
      //   show: true,
      //   title: 'Processing...',
      // },
      buttonLoading: true,
    });
    let body = this.state.body;

    // alert(JSON.stringify(body)); return;
    API.post('serial-code', body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          buttonLoading: false,
        });
        if (result.status) {
          this.props.refresh();
          this.setState({
            downloadPath: result.data?.path_file,
            notif: {
              title: 'Success',
              status: result.status,
              message: result.message,
            },
          });

          setTimeout(() => {
            this.setState({
              notif: { show: false }
            });
          }, 1500);
        }
      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
            status: false,
          }
        });
      })
  }

  onClose = () => {
    if (this.state.buttonLoading) return;
    this.props.close();
    this.onReset();    
  }

  onReset = () => {
    this.setState({
      error: {},
      disabled: true,
      buttonLoading: false,
      body: {
        manufacturer_id: null,
        start_serial: '',
        end_serial: '',
        roll: '',
        shipping_date: '',
      },
      notif: {
        show: false,
        title: '',
        message: '',
        status: true,
      },
      downloadPath: null,
      qty: 0,
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.onClose()}>
          <ModalHeader toggle={() => this.onClose()}>Generate New</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="exampleEmail">
                  Quantity <span className="text-danger">*</span>
                </Label>
                <Input
                  name="qty"
                  placeholder="Enter Quantity"
                  type="text"
                  onChange={this.onChange}
                  value={addCommas2(this.state.body.qty)}
                  required
                  invalid={this.state.error['qty'] ? true : false}
                />
                <FormFeedback >{this.state.error['end_serial']}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">
                  Start Serial
                </Label>
                <Input
                  name="start_serial"
                  placeholder="Enter start serial"
                  type="text"
                  onChange={this.onChange}
                  required
                  invalid={this.state.error['start_serial'] ? true : false}
                />
                <FormFeedback >{this.state.error['start_serial']}</FormFeedback>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter className={`w-100 d-flex justify-content- ${this.state.buttonLoading ? 'between' : 'between'}`}>
            {
              this.state.buttonLoading ?
                <div className="d-flex justify-content-start" style={{ maxWidth: '75%' }}>
                  <div className="text-muted text-sm">Please do not close/refresh this page until the process is complete</div>
                </div>
                : null
            }
            <div className={`d-flex gap-2 justify-content-end ${this.state.downloadPath ? 'w-100' : ''}`}>
              {
                this.state.downloadPath ?
                  <div className="d-flex justify-content-between w-100">
                    <Button color="danger" onClick={() => this.onReset()}>Reset</Button>
                    <Button color="primary" href={this.state.downloadPath} download>Download CSV</Button>
                  </div>
                  : <>
                    <Button color="info" onClick={() => this.onSubmit()} disabled={this.state.disabled}>
                      {!this.state.buttonLoading ? 'Submit' : <>Process <Spinner size="sm" /></>}
                    </Button>
                    {
                      this.state.buttonLoading ? null : <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
                    }
                  </>
              }
            </div>
          </ModalFooter>
        </Modal>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
      </>
    )
  }
}