module.exports = Object.freeze({

  // MENUS CODE
  MENU_CREATE_TOKEN: 'create-token',
  MENU_LIST_QRCODE: 'list-qr-code',
  MENU_REPLACE_QRCODE: 'replace-qr-code',
  MENU_LABELING_QRCODE: 'labeling-qr-code',
  MENU_LATEST_SCAN_QRCODE: 'latest-scan-qr-code',
  MENU_SUSPICIOUS_SCAN_QRCODE: 'suspicious-qr-code',
  MENU_ADD_MANUFACTURER: 'add-manufacturer',
  MENU_LIST_MANUFACTURER: 'list-manufacturer',
  MENU_LIST_CUSTOMER: 'list-customer',
  MENU_ADD_DISTRIBUTOR: 'add-distributor',
  MENU_LIST_DISTRIBUTOR: 'list-distributor',
  MENU_ADMIN_USER_DISTRIBUTOR: 'admin-user-distributor',
  MENU_MANAGE_PARTNER_USERS: 'manage-partner-users',
  MENU_IMPORT_CSV : 'import-csv',
  MENU_ADD_PRODUCT_ID : 'add-product-id',
  MENU_LIST_PRODUCT_ID : 'list-product-id',
  MENU_IMPORT_CERTIFICATE : 'import-certificate',
  MENU_LIST_CITY_QR_LOCATION : 'list-city-qr-location',
  MENU_LIST_UNDONE_QUEUE_JOBS : 'list-undone-queue-jobs',
  MENU_LIST_DONE_QUEUE_JOBS : 'list-done-queue-jobs',
  MENU_MANAGE_USER : 'manage-user',
  MENU_ROLE_AKSES : 'role-akses',
  MENU_MANAGE_MENU : 'manage-menu',
  MENU_API_ENDPOINT : 'api-endpoint',
  MENU_LIST_FORUM : 'list-forum',
  MENU_REPORTED_FORUM : 'reported-forums',
  MENU_REPORTED_COMMENT : 'reported-comments',
  MENU_SUSPICIOUS_EMAIL : 'suspicious-email-recipient-list',
  MENU_AFFILIATE_LEADS : 'approval-leads',
  MENU_MANAGE_AFFILIATE_USERS : 'manage-affiliate-users',
  MENU_COMMISSION_CONFIG : 'commission-config',
  MENU_GENERATE_QR_CSV : 'generate-qr-csv',
  MENU_SCN_MEMBER : 'member',
  MENU_SCN_MISSION_WORK : 'mission-work',
  MENU_SCN_POINT_CONVERSION : 'point-conversion',
  MENU_SCN_WITHDRAWAL : 'withdrawal',
  MENU_SCN_PURCHASED_POINT : 'purchased-point',
  MENU_LEADS_TRACKING_TYPE_PRODUCT : 'type-product',
  MENU_LEADS_TRACKING_DATA_LEADS : 'data-leads',
  MENU_LEADS_TRACKING_OFFERING_LETTER : 'offering-letter',
  MENU_LEADS_TRACKING_OFFERING_LETTER_APPROVAL : 'offering-letter-approval',
  MENU_LEADS_TRACKING_DASHBOARD : 'leads',
  MENU_SCN_CONFIG_POINT : 'config-referral',
  MENU_CONFIG_POPUP : 'config-popup',
  MENU_SCN_DASHBOARD : 'dashboard-scn',
  MENU_QR_FOOM : 'qr-foom',
});