import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Spinner,
  InputGroup,
  Input,
  Button,
  Form
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import { formatDate } from 'src/lib/helpers';
import ModalUpdate from './component/ModalUpdate';
import ModalCreate from './component/ModalCreate';
import moment from 'moment';

class QrcodeFoomIndex extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_QR_FOOM,
      scope: [],
      columns: [
        // {
        //   name: 'ID',
        //   selector: 'id',
        //   sortable: true,
        // },
        // {
        //   name: 'Manufactur Name',
        //   selector: 'manufacturer?.name',
        //   sortable: true,
        // },
        {
          name: 'Serial Number',
          selector: 'serial',
          sortable: true
        },
        {
          name: 'Unique Code',
          selector: 'unique_code',
          sortable: true,
        },
        {
          name: 'Roll Number',
          sortable: true,
          cell: data => data.roll ?? '-'
        },
        {
          name: 'Shipping Date',
          selector: 'shipping_date',
          sortable: true,
          cell: data => data.shipping_date ? moment.utc(data.shipping_date).format('DD MMMM YYYY') : '-'
        },
        {
          name: 'Created At',
          sortable: true,
          cell: data =>
            <>
              {data.created_at ? formatDate(data.created_at) + ' WIB' : '-'}
            </>
        },
        {
          name: 'Updated At',
          sortable: true,
          cell: data =>
            <>
              {data.updated_at ? formatDate(data.updated_at) + ' WIB' : '-'}
            </>
        },
      ],
      loaderPage: true,
      loadingData: false,
      data: [],
      editData: {},
      id: null,
      showTable: false,
      showModal: false,
      search: '',
      fake: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      detailData: {},
      detailDataLoading: false,
      showModalExport: false,
      showModalCreate: false,
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('serial-code?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + (this.state.search ?? '')
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true,
          loadingData: false
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page,
      loadingData: true
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onSearch(e) {
    e.preventDefault();
    this.getList();
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header vertical-middle d-flex justify-content-between">
                    <strong className="card-title vertical-middle-p">QR Foom</strong>
                    <div className='d-flex gap-2'>
                      {
                        this.state.scope.includes('create') ?
                          <Button color="success" size='sm' onClick={() => this.setState({ showModalCreate: true })}>Generate New</Button>
                          : null
                      }
                      {
                        this.state.scope.includes('update') ?
                          <Button type='button' size='sm' color="primary" onClick={() => this.setState({ showModalExport: true })}>Update Roll & Shipping Date</Button>
                          : null
                      }
                    </div>
                  </div>
                  <div className="card-body">
                    <Form className='d-flex justify-content-between mb-3' onSubmit={(e) => this.onSearch(e)}>
                      <InputGroup className='w-auto'>
                        <Input
                          placeholder='Search Serial Number'
                          onChange={(e) => this.setState({ search: e.target.value })}
                        />
                        <Button color="primary" onClick={() => this.getList()}>
                          <i className="fa fa-search"></i>
                        </Button>
                      </InputGroup>
                    </Form>
                    {
                      <DataTable
                        progressPending={this.state.loadingData}
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalUpdate
            open={this.state.showModalExport}
            close={() => this.setState({ showModalExport: false })}
            data={this.state.detailData}
            dataLoading={this.state.detailDataLoading}
            refresh={() => this.getList()}
          />
          <ModalCreate
            open={this.state.showModalCreate}
            close={() => this.setState({ showModalCreate: false })}
            refresh={() => this.getList()}
          />
        </div>
    )
  }
}

export default QrcodeFoomIndex;