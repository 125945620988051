import React, { Component } from 'react';
import {
    Switch,
    Route 
} from 'react-router-dom'
import ListQRCode from '../pages/qrcode/ListQRCode'
import GenerateQRCode from '../pages/qrcode/GenerateQRCode';
import ReplaceQRCode from '../pages/qrcode/ReplaceQRCode';
import LabelingQRCode from '../pages/qrcode/LabelingQRCode';
import EditQRCode from '../pages/qrcode/EditQRCode';
import DetailQRCode from '../pages/qrcode/DetailQRCode';
import ListManufacturer from '../pages/manufacturer/ListManufacturer';
import AddManufacturer from '../pages/manufacturer/AddManufacturer';
import EditManufacturer from '../pages/manufacturer/EditManufacturer';
import DetailManufacturer from '../pages/manufacturer/DetailManufacturer';
import ListProduct from '../pages/product/ListProduct';
import ListProductSelected from '../pages/product/ListProductSelected';
import AddProduct from '../pages/product/AddProduct';
import GenerateQrCsv from '../pages/product/GenerateQrCsv';
import AddProductMultiple from '../pages/product/AddProductMultiple';
import EditProduct from '../pages/product/EditProduct';
import DetailProduct from '../pages/product/DetailProduct';
import AddDistributor from '../pages/distributor/AddDistributor';
import ImportCSV from '../pages/product/ImportCSV';
import AddToken from '../pages/token/AddToken';
import ListRedeemVoucher from '../pages/redeemvoucher/ListRedeemVoucher';
import ListCity from '../pages/qrcity/ListCity'
import LoginUser from '../pages/login/LoginUser'
import Logout from '../pages/logout/Logout'
// import TestIPTV from '../pages/TestIPTV';
import SendNotif from '../pages/sendnotif/SendNotif';
import ListQueue from '../pages/queue/ListQueue';
import ListQueueCompleted from '../pages/queue/ListQueueCompleted'
import ListDistributor from '../pages/distributor/ListDistributor';
import IndexCustomer from '../pages/customer/indexCustomer';
import ListDistributorUser from '../pages/distributor/user/ListDistributorUser';
import AddDistributorUser from '../pages/distributor/user/addDistributorUser';
import EditDistributorUser from '../pages/distributor/user/editDistributorUser';
import LatestQRCode from '../pages/qrcode/LatestQRCode';
import SuspiciousQRCode from '../pages/qrcode/SuspiciousQRCode';
import Users from '../pages/partner/Users';
import addPartnerUserCustom from '../pages/partner/addPartnerUserCustom';
import userIndex from '../pages/user/index';
import roleIndex from '../pages/role/indexRole';
import addRole from '../pages/role/addRole';
import editRole from '../pages/role/editRole';
import detailRole from '../pages/role/detailRole';
import indexMenu from '../pages/menu/indexMenu';
import addMenu from '../pages/menu/addMenu';
import editMenu from '../pages/menu/editMenu';
import detailMenu from '../pages/menu/detailMenu';
import indexLog from '../pages/log/indexLog';
import detailLog from '../pages/log/detailLog';
import indexApi from '../pages/api/indexApi';
import addApi from '../pages/api/addApi';
import editApi from '../pages/api/editApi';
import detailApi from '../pages/api/detailApi';
import indexForum from '../pages/forum/indexForum';
import detailForum from '../pages/forum/detailForum';
import indexPlaceToBuy from '../pages/forum/indexPlaceToBuy';
import SuspiciousEmailList from '../pages/security/SuspiciousEmailList';
import UnauthorizedPage from '../pages/unAuthorized';
import CustomerDeletedIndex from '../pages/customer/CustomerDeletedIndex';
import CommentForumReportedIndex from '../pages/forum/comments/CommentForumReportedIndex';
import ReportedForumIndex from '../pages/forum/ReportedForumIndex';
import ReportedReasonIndex from '../pages/forum/ReportedReasonIndex';
import HistoryScan from '../pages/qrcode/HistoryScan';
import IndexBatchnumberConfig from '../pages/product/batchnumberConfig/indexBatchnumberConfig';
import AddBatchnumberConfig from '../pages/product/batchnumberConfig/addBatchnumberConfig';
import EditBatchnumberConfig from '../pages/product/batchnumberConfig/editBatchnumberConfig';
import DetailBatchnumberConfig from '../pages/product/batchnumberConfig/detailBatchnumberConfig';
import LeadsIndex from '../pages/affiliate/leads/leadsIndex';
import AffiliateUsersIndex from '../pages/affiliate/users/usersIndex';
import AffiliateUsersAdd from '../pages/affiliate/users/usersAdd';
import AffiliateUsersEdit from '../pages/affiliate/users/usersEdit';
import AffiliateUsersDetail from '../pages/affiliate/users/usersDetail';
import AffiliateConfigIndex from '../pages/affiliate/config/configIndex';
import AffiliateConfigAdd from '../pages/affiliate/config/configAdd';
import AffiliateConfigEdit from '../pages/affiliate/config/configEdit';
import AffiliateConfigDetail from '../pages/affiliate/config/configDetail';
import ConfigScanwwebIndex from '../pages/config/scanweb/scanwebIndex';
import ConfigScanwwebQuestionAdd from '../pages/config/scanweb/scanwebQuestionAdd';
import ConfigScanwwebQuestionEdit from '../pages/config/scanweb/scanwebQuestionEdit';
import ConfigScanwwebQuestionDetail from '../pages/config/scanweb/scanwebQuestionDetail';
import ScnMissionWorkIndex from '../pages/scn/missionWork/Index';
import ScnMissionWorkDetail from '../pages/scn/missionWork/Detail';
import ScnMemberIndex from '../pages/scn/member/Index';
import ScnMemberDetail from '../pages/scn/member/Detail';
import ScnMemberRegistrationIndex from '../pages/scn/member/registration/Index';
import ScnPointConversionIndex from '../pages/scn/pointConversion/Index';
import ScnPointConversionDetail from '../pages/scn/pointConversion/Detail';
import ScnPointConversionAdd from '../pages/scn/pointConversion/Add';
import ScnPointConversionEdit from '../pages/scn/pointConversion/Edit';
import ScnWithdrawalIndex from '../pages/scn/withdrawal/Index';
import ScnWithdrawalAdd from '../pages/scn/withdrawal/Add';
import ScnWithdrawalEdit from '../pages/scn/withdrawal/Edit';
import ScnWithdrawalDetail from '../pages/scn/withdrawal/Detail';
import ScnPurchasedPointIndex from '../pages/scn/purchasedPoint/Index';
import TypeProductIndex from '../pages/leadsTracking/typeProduct/Index';
import TypeProductEdit from '../pages/leadsTracking/typeProduct/Edit';
import TypeProductDetail from '../pages/leadsTracking/typeProduct/Detail';
import TypeProductAdd from '../pages/leadsTracking/typeProduct/Add';
import indexDashboard from '../pages/dashboard/indexDashboard';
import DataLeadsIndex from '../pages/leadsTracking/dataLeads/Index';
import DataLeadsAdd from '../pages/leadsTracking/dataLeads/Add';
import DataLeadsEdit from '../pages/leadsTracking/dataLeads/Edit';
import DataLeadsProduct from '../pages/leadsTracking/dataLeads/product/Add';
import DataLeadsDetail from '../pages/leadsTracking/dataLeads/Detail';
import offeringLetterIndex from '../pages/leadsTracking/offeringLetter/Index';
import offeringLetterAdd from '../pages/leadsTracking/offeringLetter/Add';
import offeringLetterEdit from '../pages/leadsTracking/offeringLetter/Edit';
import offeringLetterDetail from '../pages/leadsTracking/offeringLetter/Detail';
import ManufacturerGuestIndex from '../pages/manufacturer/guest/Index';
import indexReferral from '../pages/scn/configReferral/indexConfigReferral';
import detailReferral from '../pages/scn/configReferral/detailConfigReferral';
import editReferral from '../pages/scn/configReferral/editConfigReferral';
import addReferral from '../pages/scn/configReferral/addConfigReferral';
import configIndex from '../pages/configpopup/index';
import indexDashboardScn from '../pages/dashboardScn/indexDashboardScn';
import QrcodeFoomIndex from '../pages/qrcode/foom/Index';

export default class Routes extends Component {
    
    render() {
        var login = localStorage.getItem('isLoggedIn');
        if(login === 'true'){
            return(
                // <Router>
                    <Switch>
                        {/* SETTING */}   
                        <Route path='/dashboard' component={indexDashboard}/>

                        <Route path='/qrcity/' component={ListCity}/>
                        <Route path='/qrcode/generate' component={GenerateQRCode}/>
                        <Route path='/qrcode/history-scan' component={HistoryScan}/>
                        <Route path='/sendnotif' component={SendNotif}/>
                        <Route path='/qrcode/replace' component={ReplaceQRCode}/>
                        <Route path='/qrcode/labeling' component={LabelingQRCode}/>
                        <Route path='/qrcode/edit/:id' component={EditQRCode}/>
                        <Route path='/qrcode/latest' component={LatestQRCode}/>
                        <Route path='/qrcode/suspicious' component={SuspiciousQRCode}/>
                        <Route path='/qrcode/foom' component={QrcodeFoomIndex}/>
                        <Route path='/qrcode/:id' component={DetailQRCode}/>
                        <Route path='/partner/users' component={Users}/>
                        <Route path="/custom/partner/add" component={addPartnerUserCustom} />
                        <Route path='/manufacturer/add' component={AddManufacturer}/>
                        <Route path='/manufacturer/guest' component={ManufacturerGuestIndex}/>
                        <Route path='/manufacturer/edit/:id' component={EditManufacturer}/>
                        <Route path='/manufacturer/:id' component={DetailManufacturer}/>
                        <Route path='/manufacturer/' component={ListManufacturer}/>
                        <Route path='/product/add' component={AddProduct}/>
                        <Route path='/generate/qrcsv' component={GenerateQrCsv}/>
                        <Route path='/product/add-multiple' component={AddProductMultiple}/>
                        <Route path='/product/import-csv' component={ImportCSV}/>
                        <Route path='/product/batchnumber/config/add/:id' component={AddBatchnumberConfig}/>
                        <Route path='/product/batchnumber/config/edit/:id' component={EditBatchnumberConfig}/>
                        <Route path='/product/batchnumber/config/:id' component={DetailBatchnumberConfig}/>
                        <Route path='/product/batchnumber/config' component={IndexBatchnumberConfig}/>
                        <Route path='/product/edit/:id' component={EditProduct}/>
                        <Route path='/product/:id' component={DetailProduct}/>
                        <Route path='/product/' component={ListProduct}/>
                        <Route path='/productselected/' component={ListProductSelected}/>
                        <Route path='/token/add' component={AddToken}/>
                        <Route path='/queues/pending' component={ListQueue}/>
                        <Route path='/queues/complete' component={ListQueueCompleted}/>
                        <Route path='/logout' component={Logout}/>
                        <Route path='/user' component={userIndex}/>
                        
                        {/* SETTING */}                        
                        <Route path="/role/api" component={roleIndex} />
                        <Route path="/role/partner" component={roleIndex} />
                        <Route path="/role/add" component={addRole} />
                        <Route path="/role/edit/:id" component={editRole} />
                        <Route path="/role/:id" component={detailRole} />
                        <Route path="/role" component={roleIndex} />
                        <Route path="/menu/add" component={addMenu} />
                        <Route path="/menu/edit/:id" component={editMenu} />
                        <Route path="/menu/:id" component={detailMenu} />
                        <Route path="/menu" component={indexMenu} />
                        <Route path="/log/:id" component={detailLog} />
                        <Route path="/log" component={indexLog} />
                        <Route path="/api/add" component={addApi} />
                        <Route path="/api/edit/:id" component={editApi} />
                        <Route path="/api/:id" component={detailApi} />
                        <Route path="/api" component={indexApi} />
                        <Route path='/config-popup' component={configIndex}/>

                        {/* FORUM */}     
                        <Route path="/forum/comment/reported" component={CommentForumReportedIndex} />
                        <Route path="/forum/reported/reason" component={ReportedReasonIndex} />
                        <Route path="/forum/reported" component={ReportedForumIndex} />
                        <Route path="/forum/place-to-buy" component={indexPlaceToBuy} />
                        <Route path="/forum/:id" component={detailForum} />
                        <Route path="/forum" component={indexForum} />

                        {/* CUSTOMER */} 
                        <Route path="/customer/deleted" component={CustomerDeletedIndex} />
                        <Route path='/customer' component={IndexCustomer}/>

                        
                        <Route exact path='/' component={ListQRCode}/>
                        <Route path='/distributor/add' component={AddDistributor}/>
                        <Route path='/distributor/user/add' component={AddDistributorUser}/>
                        <Route path='/distributor/user/edit/:id' component={EditDistributorUser}/>
                        <Route path='/distributor/user' component={ListDistributorUser}/>
                        <Route path='/distributor' component={ListDistributor}/>
                        <Route path='/security/suspicious-email-recipient-list' component={SuspiciousEmailList}/>

                        <Route path='/unauthorized' component={UnauthorizedPage}/>

                        {/* AFFILIATE */}     
                        <Route path="/affiliate/leads" component={LeadsIndex} />
                        <Route path="/affiliate/users/add" component={AffiliateUsersAdd} />
                        <Route path="/affiliate/users/edit/:id" component={AffiliateUsersEdit} />
                        <Route path="/affiliate/users/:id" component={AffiliateUsersDetail} />
                        <Route path="/affiliate/users" component={AffiliateUsersIndex} />
                        <Route path="/affiliate/config/add" component={AffiliateConfigAdd} />
                        <Route path="/affiliate/config/edit/:id" component={AffiliateConfigEdit} />
                        <Route path="/affiliate/config/:id" component={AffiliateConfigDetail} />
                        <Route path="/affiliate/config" component={AffiliateConfigIndex} />

                        {/* SCN */}
                        <Route path="/scn/mission-work" component={ScnMissionWorkIndex} />
                        <Route path="/scn/mission-work/:id" component={ScnMissionWorkDetail} />
                        <Route path="/scn/member/registration" component={ScnMemberRegistrationIndex} />
                        <Route path="/scn/member/:id" component={ScnMemberDetail} />
                        <Route path="/scn/member" component={ScnMemberIndex} />
                        <Route path="/scn/point-conversion/add" component={ScnPointConversionAdd} />
                        <Route path="/scn/point-conversion/edit/:id" component={ScnPointConversionEdit} />
                        <Route path="/scn/point-conversion/:id" component={ScnPointConversionDetail} />
                        <Route path="/scn/point-conversion" component={ScnPointConversionIndex} />
                        <Route path="/scn/withdrawal/add" component={ScnWithdrawalAdd} />
                        <Route path="/scn/withdrawal/edit/:id" component={ScnWithdrawalEdit} />
                        <Route path="/scn/withdrawal/:id" component={ScnWithdrawalDetail} />
                        <Route path="/scn/withdrawal" component={ScnWithdrawalIndex} />
                        <Route path="/scn/purchased-point" component={ScnPurchasedPointIndex} />
                        <Route path="/scn/config/referral/add" component={addReferral} />
                        <Route path="/scn/config/referral/edit/:id/:type" component={editReferral} />
                        <Route path="/scn/config/referral/:id/:type" component={detailReferral} />
                        <Route path="/scn/config/referral" component={indexReferral} />
                        <Route path='/dashboard-scn' component={indexDashboardScn}/>

                        {/* LEADS TRACKING */}
                        <Route path="/leads-tracking/type-product/add" component={TypeProductAdd} />
                        <Route path="/leads-tracking/type-product/edit/:id" component={TypeProductEdit} />
                        <Route path="/leads-tracking/type-product/:id" component={TypeProductDetail} />
                        <Route path="/leads-tracking/type-product" component={TypeProductIndex} />
                        <Route path="/leads-tracking/data-leads/add" component={DataLeadsAdd} />
                        <Route path="/leads-tracking/data-leads/edit/:id" component={DataLeadsEdit} />
                        <Route path="/leads-tracking/data-leads/product/:id" component={DataLeadsProduct} />
                        <Route path="/leads-tracking/data-leads/:id" component={DataLeadsDetail} />
                        <Route path="/leads-tracking/data-leads" component={DataLeadsIndex} />
                        <Route path="/leads-tracking/offering-letter/add/:id" component={offeringLetterAdd} />
                        <Route path="/leads-tracking/offering-letter/edit/:id" component={offeringLetterEdit} />
                        <Route path="/leads-tracking/offering-letter/:id" component={offeringLetterDetail} />
                        <Route path="/leads-tracking/offering-letter" component={offeringLetterIndex} />

                         {/* CONFIG */}     
                         <Route path="/config/scan-web/question/add" component={ConfigScanwwebQuestionAdd} />
                         <Route path="/config/scan-web/question/edit/:id" component={ConfigScanwwebQuestionEdit} />
                         <Route path="/config/scan-web/question/:id" component={ConfigScanwwebQuestionDetail} />
                         <Route path="/config/scan-web" component={ConfigScanwwebIndex} />

                         {/* REDEEM VOUCHER */}                         
                        <Route path='/redeemvoucher/list' component={ListRedeemVoucher}/>

                        {/* <Route path='/iptvtest/' component={TestIPTV}/> */}
                    </Switch>
                // </Router>
            )
        } else {
            return(
                // <Router>
                    <Switch>
                        <Route exact path='/' component={LoginUser}/>
                    </Switch>
                // </Router>
            )
        }
    }
}